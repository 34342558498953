/* eslint-disable react/no-multi-comp */

import keys from 'lodash/keys';
import React, { Component, ReactNode } from 'react';
import { lazy, Suspense } from 'react';

import buildCar from './BuildCar';
import carHero from './CarHero/CarHero'; /* webpackPreload: true */
import dealersOverview from './DealersOverview';
import teaserPlug from './forms/TeaserPlug'; /* webpackPreload: true */
import headerWithBody from './HeaderWithBody';
import hero from './Hero'; /* webpackPreload: true */
import imagePlug from './ImagePlug';
import mapPlug from './MapPlug';
import mediaHero from './MediaHero/'; /* webpackPreload: true */
import Plug from './Plug';

// import accordionInner from './AccordionInner';
// import accordionPlug from './AccordionPlug';
// import articlePreview from './ArticlePreview';
// import banner from './Banner'; /* webpackPreload: true */
// import buildCarEntrance from './BuildCarEntrance/BuildCarEntrance';
// import campaignCard from './CampaignCard';
// import campaignPlug from './CampaignPlug';
// import campaigns from './Campaigns';
// import carsOverview from './CarsOverview'; /* webpackPreload: true */
// import compareModels from './CompareModels/CompareModels';
// import contactCtaPlug from './ContactCtaPlug';
// import dealersOverview from './DealersOverview';
// import contactPlug from './forms/ContactPlug';
// import servicePlug from './forms/ServicePlug';
// import testDrivePlug from './forms/TestDrivePlug';
// import headerWithBody from './HeaderWithBody'; /* webpackPreload: true */
// import headerWithCta from './HeaderWithCta'; /* webpackPreload: true */

const accordionInner = lazy(() => import('./AccordionInner'));
const accordionPlug = lazy(() => import('./AccordionPlug'));
const articlePreview = lazy(() => import('./ArticlePreview'));
const banner = lazy(() => import('./Banner'));
const buildCarEntrance = lazy(() => import('./BuildCarEntrance/BuildCarEntrance'));
const campaignCard = lazy(() => import('./CampaignCard'));
const campaignPlug = lazy(() => import('./CampaignPlug'));
const campaigns = lazy(() => import('./Campaigns'));
const carsOverview = lazy(() => import('./CarsOverview'));

const compareModels = lazy(() => import('./CompareModels/CompareModels'));
const contactCtaPlug = lazy(() => import('./ContactCtaPlug'));
const contactPlug = lazy(() => import('./forms/ContactPlug'));
const servicePlug = lazy(() => import('./forms/ServicePlug'));
const testDrivePlug = lazy(() => import('./forms/TestDrivePlug'));
const headerWithCta = lazy(() => import('./HeaderWithCta'));

// const NoPlug = dynamic(() => import('./NoPlug';
// Load all as normal imports
// import heroWithPrintButton from './HeroWithPrintButton'; /* webpackPreload: true */
// import imageGrid from './ImageGrid';
// import imageTextCta from './ImageTextCta';
// import imageWithTextAndLink from './ImageWithTextAndLink';
// import seperator from './Seperator';

const heroWithPrintButton = lazy(() => import('./HeroWithPrintButton'));
const imageGrid = lazy(() => import('./ImageGrid'));
const imageTextCta = lazy(() => import('./ImageTextCta'));
const imageWithTextAndLink = lazy(() => import('./ImageWithTextAndLink'));
const seperator = lazy(() => import('./Seperator'));

// import mapPlug from './MapPlug';
const NoPlug = lazy(() => import('./NoPlug'));
const numbersSection = lazy(() => import('./NumbersSection'));
const NewPlug = lazy(() => import('./Plug/NewPlug'));
const quoteWithImage = lazy(() => import('./QuoteWithImage'));
const singleImg = lazy(() => import('./SingleImg'));
const slider = lazy(() => import('./Slider'));
const smallGallery = lazy(() => import('./SmallGallery'));
const specificationsPlug = lazy(() => import('./Specifications/Specifications'));
const splitWithToggle = lazy(() => import('./SplitWithToggleTailwind'));
const textWithLinks = lazy(() => import('./TextWithLinks'));
const threeRowCtas = lazy(() => import('./ThreeRowCtas'));
const videoModule = lazy(() => import('./VideoModule'));

const mediaWithSpecs = lazy(() => import('./MediaWithSpecs/MediaWithSpecs'));
const mediaWithText = lazy(() => import('./MediaWithText/MediaWithText'));
const priceList = lazy(() => import('./PriceList'));
const teaser = lazy(() => import('./Teaser'));
const newsletter = lazy(() => import('./Newsletter/Newsletter'));

// import mediaWithSpecs from './MediaWithSpecs/MediaWithSpecs';
// import mediaWithText from './MediaWithText/MediaWithText';
// import priceList from './PriceList'; /* webpackPreload: true */

// import NoPlug from './NoPlug';
// import numbersSection from './NumbersSection';
// import NewPlug from './Plug/NewPlug';
// import quoteWithImage from './QuoteWithImage';
// import singleImg from './SingleImg';
// import slider from './Slider';
// import smallGallery from './SmallGallery';
// import specificationsPlug from './Specifications/Specifications';
// import splitWithToggle from './SplitWithToggle/SplitWithToggle';
// import teaser from './Teaser'; /* webpackPreload: true */
// import textWithLinks from './TextWithLinks';
// import threeRowCtas from './ThreeRowCtas';
// import videoModule from './VideoModule'; /* webpackPreload: true */
// const campaign = dynamic(() => import('./Campaign'));

// Load all as dynamic imports
const enabledPlugs = {
  hero,
  carsOverview,
  dealersOverview,
  threeRowCtas,
  quoteWithImage,
  campaignPlug,
  campaignCard,
  mapPlug,
  campaigns,
  imageWithTextAndLink,
  banner,
  headerWithBody,
  headerWithCta,
  priceList,
  seperator,
  heroWithPrintButton,
  testDrivePlug,
  teaser,
  servicePlug,
  teaserPlug,
  contactPlug,
  slider,
  accordionInner,
  contactCtaPlug,
  textWithLinks,
  imageGrid,
  imagePlug,

  accordionPlug,
  // teaser,
  singleImg,
  smallGallery,
  numbersSection,
  imageTextCta,
  videoModule,

  // campaign,
  articlePreview,
  // build car
  buildCar,
  mediaWithText,
  mediaWithSpecs,
  carHero,
  mediaHero,
  splitWithToggle,
  buildCarEntrance,
  compareModels,
  specificationsPlug,
  newsletter,
};

type Plug = {
  _key: string;
  _type: string;
  partOfSimilarList?: boolean;
};

type PlugProps = {
  plugs: Plug[];
};

const newTypes = [
  'carHero',
  'testDrivePlug',
  'mediaWithSpecs',
  'mediaWithText',
  'newsletter',
  'splitWithToggle',
  'buildCarEntrance',
  'priceList',
  'compareModels',
  'specificationsPlug',
  'mediaHero',
];

class PlugsResolver extends Component<PlugProps> {
  resolvePlug = (plug: Plug): JSX.Element => {
    const Component = enabledPlugs[plug._type];

    if (Component && newTypes.includes(plug._type)) {
      return (
        <NewPlug key={plug._key}>
          <Component {...plug} />
        </NewPlug>
      );
    }

    if (Component) {
      return (
        <Plug key={plug._key}>
          <Component {...plug} />
        </Plug>
      );
    }

    return <NoPlug type={plug._type} key={plug._key} />;
  };

  render(): JSX.Element | ReactNode {
    const { plugs }: PlugProps = this.props;

    if (!plugs || !plugs.map) {
      return <div>No plugs</div>;
    }
    let lastPlug = '';

    const elements: ReactNode[] = plugs.map((plug: Plug, key) => {
      if (keys(plug).length === 0) {
        return <div key={plug._key}> Wait for conditional in GROQ </div>;
      }

      plug.partOfSimilarList = lastPlug === plug._type;
      lastPlug = plug._type;

      // if ([0, 2, 4, 6, 8].includes(key)) {
      // if ([10, 12, 14, 16, 18].includes(key)) {
      // if ([20, 22, 24, 26, 28].includes(key)) {
      // if ([30, 32,34, 36, 38].includes(key)) {
      // if ([40, 42, 44, 46, 48].includes(key)) {
      // if ([40].includes(key)) {
      // console.log(plug._type);

      return (
        <Suspense key={key} fallback={<div></div>}>
          {this.resolvePlug(plug)}
        </Suspense>
      );
    });

    return elements;
  }
}

export default PlugsResolver;
